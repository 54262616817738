var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-main',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"driver-view"},[(_vm.displayTopBar)?_c('section',{staticClass:"top-bar"},[_c('h1',{staticClass:"font-weight-light mb-2"},[_vm._v("$120")]),_c('span',{staticClass:"overline"},[_vm._v("Your delivery request will cost the above amount")])]):_vm._e(),_c('GmapMap',{ref:"map",staticClass:"map",attrs:{"center":{ lat: _vm.lat, lng: _vm.lng },"zoom":10,"map-type-id":"terrain","options":{
              zoomControl: true,
              mapTypeControl: true,
              scaleControl: true,
              streetViewControl: true,
              rotateControl: true,
              fullscreenControl: true,
              disableDefaultUI: true,
            }}},_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"position":m.position,"clickable":true,"draggable":true},on:{"click":function($event){_vm.center = m.position}}})}),1),_c('section',{staticClass:"bottom-bar"},[_c('div',{staticClass:"latLngLabel"},[_vm._v(_vm._s(_vm.lat)+", "+_vm._s(_vm.lng))]),_c('button',{staticClass:"ui button green",on:{"click":_vm.startLocationUpdates}},[_c('i',{staticClass:"circle dot outline icon large"}),_vm._v(" Continue ")]),_c('button',{staticClass:"ui button red",on:{"click":_vm.stopLocationUpdates}},[_c('i',{staticClass:"circle dot outline icon large"}),_vm._v(" Cancel ")])])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }