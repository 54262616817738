<template>
  <v-app>
    <v-main>
      <v-row>
        <v-col cols="12" class="py-0">
          <div class="driver-view">
            <section v-if="displayTopBar" class="top-bar">
              <h1 class="font-weight-light mb-2">$120</h1>
              <span class="overline"
                >Your delivery request will cost the above amount</span
              >
            </section>

            <GmapMap
              :center="{ lat: lat, lng: lng }"
              :zoom="10"
              map-type-id="terrain"
              ref="map"
              class="map"
              :options="{
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: true,
                rotateControl: true,
                fullscreenControl: true,
                disableDefaultUI: true,
              }"
            >
              <GmapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                :draggable="true"
                @click="center = m.position"
              />
            </GmapMap>

            <section class="bottom-bar">
              <div class="latLngLabel">{{ lat }}, {{ lng }}</div>
              <button class="ui button green" @click="startLocationUpdates">
                <i class="circle dot outline icon large"></i>
                Continue
              </button>

              <button class="ui button red" @click="stopLocationUpdates">
                <i class="circle dot outline icon large"></i>
                Cancel
              </button>
            </section>
          </div>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
import firebase from "firebase";
export default {
  template: "#page",
  data() {
    return {
      displayTopBar: true,
      lat: 0.315133,
      lng: 32.576353,
      watchPositionId: null,
    };
  },

  mounted() {},

  methods: {
    startLocationUpdates() {
      this.watchPositionId = navigator.geolocation.watchPosition(
        (position) => {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          this.updateLocation(this.lat, this.lng);
        },
        (error) => {
          console.log(error.message);
        }
      );
    },

    stopLocationUpdates() {
      navigator.geolocation.clearWatch(this.watchPositionId);
    },
    updateLocation(lat, lng) {},
  },
};
</script>

<style>
.driver-view {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.top-bar {
  padding: 20px 0px;
  height: 120px;
  text-align: center;
}

.map {
  flex-grow: 1;
  background-color: rgba(0, 0, 0, 0.1);
}

.bottom-bar {
  padding: 20px 0px;
  text-align: center;
}
</style>
